import React, { useState } from 'react';
import { registerUser } from '../services/registrationService';
import { checkEmailExists, checkUsernameExists } from '../services/checkService';
import { Form, Input, Button, Typography, Alert } from 'antd';

const { Title } = Typography;

function Register() {
    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [message, setMessage] = useState('');
    const [error, setError] = useState('');

    const handleSubmit = async () => {
        setMessage('');
        setError('');

        try {
            const emailExists = await checkEmailExists(email);
            const usernameExists = await checkUsernameExists(username);

            if (emailExists || usernameExists) {
                setError('Email or username already exists.');
            } else {
                const success = await registerUser(username, email, password);

                if (success) {
                    setMessage('Registration successful! Please login.');
                    setUsername('');
                    setEmail('');
                    setPassword('');
                } else {
                    setError('Registration failed. Please try again1.');
                }
            }
        } catch (error) {
            console.error('Registration error:', error);
            setError('Registration failed. Please try again2.');
        }
    };

    return (
        <div style={{ maxWidth: '300px', margin: 'auto', marginTop: '50px' }}>
            <Title level={2}>Register</Title>
                 {error && <Alert type="error" message={error} />}
            <Form onFinish={handleSubmit} layout="vertical">
                <Form.Item label="Username" required>
                    <Input value={username} onChange={(e) => setUsername(e.target.value)} />
                </Form.Item>
                <Form.Item label="Email" required>
                    <Input value={email} onChange={(e) => setEmail(e.target.value)} type="email" />
                </Form.Item>
                <Form.Item label="Password" required>
                    <Input.Password value={password} onChange={(e) => setPassword(e.target.value)} />
                </Form.Item>
                <Form.Item>
                    <Button type="primary" htmlType="submit">Register</Button>
                </Form.Item>
            </Form>
            {message && <Alert message={message} type="success" showIcon />}
            {error && <Alert message={error} type="error" showIcon />}
        </div>
    );
}

export default Register;
