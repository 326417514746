// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Day Mode Styles */
.site-layout {
    transition: background-color 0.3s ease, color 0.3s ease;
    background-color: #fff;
    color: #000;
}

.site-layout .ant-layout-sider {
    background-color: #001529;
    color: #fff;
}

/* Night Mode Styles */
.site-layout.night-mode {
    background-color: #000;
    color: #fff;
}

.site-layout.night-mode .ant-layout-sider {
    background-color: #001529;
    color: #fff;
}

.site-layout.night-mode .ant-menu-dark {
    background-color: #001529;
    color: #fff;
}

.site-layout.night-mode .ant-menu-dark .ant-menu-item-selected {
    background-color: #002140;
    color: #fff;
}

.site-layout.night-mode .ant-menu-dark .ant-menu-item:hover {
    background-color: #00304D;
    color: #fff;
}

/* Customize header, content, and footer styles for day and night modes as needed */
.site-layout .ant-layout-header,
.site-layout .ant-layout-content,
.site-layout .ant-layout-footer {
    background-color: #fff;
    color: #000;
}

.site-layout.night-mode .ant-layout-header,
.site-layout.night-mode .ant-layout-content,
.site-layout.night-mode .ant-layout-footer {
    background-color: #000;
    color: #fff;
}
`, "",{"version":3,"sources":["webpack://./src/styles/app.css"],"names":[],"mappings":"AAAA,oBAAoB;AACpB;IACI,uDAAuD;IACvD,sBAAsB;IACtB,WAAW;AACf;;AAEA;IACI,yBAAyB;IACzB,WAAW;AACf;;AAEA,sBAAsB;AACtB;IACI,sBAAsB;IACtB,WAAW;AACf;;AAEA;IACI,yBAAyB;IACzB,WAAW;AACf;;AAEA;IACI,yBAAyB;IACzB,WAAW;AACf;;AAEA;IACI,yBAAyB;IACzB,WAAW;AACf;;AAEA;IACI,yBAAyB;IACzB,WAAW;AACf;;AAEA,mFAAmF;AACnF;;;IAGI,sBAAsB;IACtB,WAAW;AACf;;AAEA;;;IAGI,sBAAsB;IACtB,WAAW;AACf","sourcesContent":["/* Day Mode Styles */\n.site-layout {\n    transition: background-color 0.3s ease, color 0.3s ease;\n    background-color: #fff;\n    color: #000;\n}\n\n.site-layout .ant-layout-sider {\n    background-color: #001529;\n    color: #fff;\n}\n\n/* Night Mode Styles */\n.site-layout.night-mode {\n    background-color: #000;\n    color: #fff;\n}\n\n.site-layout.night-mode .ant-layout-sider {\n    background-color: #001529;\n    color: #fff;\n}\n\n.site-layout.night-mode .ant-menu-dark {\n    background-color: #001529;\n    color: #fff;\n}\n\n.site-layout.night-mode .ant-menu-dark .ant-menu-item-selected {\n    background-color: #002140;\n    color: #fff;\n}\n\n.site-layout.night-mode .ant-menu-dark .ant-menu-item:hover {\n    background-color: #00304D;\n    color: #fff;\n}\n\n/* Customize header, content, and footer styles for day and night modes as needed */\n.site-layout .ant-layout-header,\n.site-layout .ant-layout-content,\n.site-layout .ant-layout-footer {\n    background-color: #fff;\n    color: #000;\n}\n\n.site-layout.night-mode .ant-layout-header,\n.site-layout.night-mode .ant-layout-content,\n.site-layout.night-mode .ant-layout-footer {\n    background-color: #000;\n    color: #fff;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
