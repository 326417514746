// src/services/authService.js
// Handles user authentication-related actions such as login, registration, 
// and password reset.

const API_URL = process.env.REACT_APP_API_URL;

const loginUser = async (email, password) => {
    console.log("Logging in with:", email, password);
    try {
        const response = await fetch(`${API_URL}/users/login`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ email, password }),
        });

        if (response.ok) {
            const data = await response.json();
            if (data.success && data.token) {
                localStorage.setItem('authToken', data.token); // Store the token
                localStorage.setItem('username', data.user.username); // Store the username
                localStorage.setItem('id', data.user.id); // Store the username
                // Optionally, redirect user to dashboard or another page
                // useHistory().push('/dashboard');
                return data; // Return the data in case you need it
            } else {
                throw new Error('Failed to login. Token not received. authService.js');
            }
        } else {
            throw new Error('Failed to login. Please check your credentials. authService.js');
        }
    } catch (error) {
        console.error('Login error:', error);
        throw error;
    }
};
export { loginUser };