// Dashboard.js
import React, { useState, useEffect } from 'react';
import { useNavigate, Route, Routes } from 'react-router-dom';
import axios from 'axios';
import { Typography, message } from 'antd';
import SupplierPage from './SupplierPage';
import SharedLayout from './SharedLayout';  // Import SharedLayout component

const { Title } = Typography;
const API_URL = process.env.REACT_APP_API_URL;

function Dashboard() {
    const [user, setUser] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        const token = localStorage.getItem('authToken');
        if (token) {
            axios.get(`${API_URL}/users/protected`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            })
            .then(response => {
                if (response.status === 200) {
                    setUser(response.data);
                } else {
                    message.error('Authentication failed. Redirecting to login.');
                    navigate('/');
                }
            })
            .catch(error => {
                console.error('Authentication check failed:', error);
                message.error('Error during authentication. Redirecting to login.');
                navigate('/');
            });
        } else {
            navigate('/');
        }
    }, [navigate]);

    return (
        <SharedLayout>  {/* Use SharedLayout here */}
            <div style={{ padding: 24, minHeight: 360 }}>
                {user !== null ? (
                    <Title>Welcome, {user.username}!</Title>
                ) : (
                    <Title>Welcome, Guest!</Title>
                )}
            </div>
            <Routes>
                <Route path="/suppliers" element={<SupplierPage />} />
            </Routes>
        </SharedLayout>
    );
}

export default Dashboard;
