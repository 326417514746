import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom'; // Assuming you are using React Router
import { resetPassword } from '../services/resetPasswordService'; // Service to call your API

function ResetPasswordForm() {
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [message, setMessage] = useState('');
    const navigate = useNavigate();
    const { token } = useParams(); // Get the token from the URL

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (newPassword !== confirmPassword) {
            setMessage("Passwords don't match!");
            return;
        }
        const success = await resetPassword(token, newPassword);
        if (success) {
            setMessage('Your password has been reset successfully.');
            // Optionally redirect the user to the login page after a short delay
            setTimeout(() => navigate('/login'), 3000);
        } else {
            setMessage('Failed to reset your password. Please try again.');
        }
    };

    return (
        <div>
            <h2>Reset Your Password</h2>
            <form onSubmit={handleSubmit}>
                <div>
                    <label>New Password:</label>
                    <input 
                        type="password" 
                        value={newPassword} 
                        onChange={(e) => setNewPassword(e.target.value)} 
                        required 
                    />
                </div>
                <div>
                    <label>Confirm New Password:</label>
                    <input 
                        type="password" 
                        value={confirmPassword} 
                        onChange={(e) => setConfirmPassword(e.target.value)} 
                        required 
                    />
                </div>
                <button type="submit">Reset Password</button>
                {message && <p>{message}</p>}
            </form>
        </div>
    );
}

export default ResetPasswordForm;
