// services/checkUser.js
const API_URL = process.env.REACT_APP_API_URL;

const checkEmailExists = async (email) => {
    try {
        const response = await fetch(`${API_URL}/users/check-user-exists`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ email }),
        });

        const data = await response.json();
        return data.exists;
    } catch (error) {
        console.error('Check email exists error:', error);
        throw error;
    }
};

const checkUsernameExists = async (username) => {
    try {
        const response = await fetch(`${API_URL}/users/check-user-exists`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ username }),
        });

        const data = await response.json();
        return data.exists;
    } catch (error) {
        console.error('Check username exists error:', error);
        throw error;
    }
};

export { checkEmailExists, checkUsernameExists };
