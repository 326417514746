// src/components/ResetPassword.js
// This component renders the reset password form and utilizes 
// resetPasswordService to handle the password reset request.

import React, { useState } from 'react';
import { Form, Input, Button, Typography, message } from 'antd';
import { MailOutlined } from '@ant-design/icons';
import { resetPassword } from '../services/resetPasswordService';

const { Title } = Typography;

function ResetPassword() {
    const [form] = Form.useForm();
    const [messageText, setMessageText] = useState('');

    const handleSubmit = async (values) => {
        const { email } = values;
        try {
            const success = await resetPassword(email);
            if (success) {
                setMessageText('A password reset link has been sent to your email.');
                form.resetFields();
            } else {
                setMessageText('Failed to send a password reset link. Please try again.');
            }
        } catch (error) {
            console.error('Password reset error:', error);
            setMessageText('An error occurred while resetting the password.');
        }
    };

    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
            <div>
                <Title level={2}>Reset Password</Title>
                <Form form={form} onFinish={handleSubmit}>
                    <Form.Item
                        name="email"
                        rules={[
                            {
                                required: true,
                                message: 'Please input your email!',
                                type: 'email',
                            },
                        ]}
                    >
                        <Input
                            prefix={<MailOutlined />}
                            placeholder="Email"
                        />
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" htmlType="submit" block>
                            Reset Password
                        </Button>
                    </Form.Item>
                </Form>
                {messageText && <p>{messageText}</p>}
            </div>
        </div>
    );
}

export default ResetPassword;

