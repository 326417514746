import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { loginUser } from '../services/authService'; // Update with your actual service path
import { Form, Input, Button, Typography, Alert } from 'antd';
import ErrorBoundary from '../services/ErrorBoundary'; // Import the error boundary component
import '../styles/app.css';

const { Title } = Typography;

function IndexPage() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [loginError, setLoginError] = useState('');

    const navigate = useNavigate();

    const handleSubmit = async () => {
        try {
            const response = await loginUser(email, password);
            console.log('Login successful:', response);
            navigate('/dashboard');
            setLoginError('');
        } catch (error) {
            console.error('Login error:', error);
            setLoginError(error.message);
        }
    };

    return (
        <div style={{ textAlign: 'center', marginTop: '20px' }}>
            <Title>Welcome</Title>
            <div style={{ maxWidth: '300px', margin: 'auto' }}>
                <Form onFinish={handleSubmit}>
                    <Form.Item
                        label="Email"
                        name="email"
                        rules={[{ required: true, message: 'Please input your email!' }]}
                    >
                        <Input type="email" onChange={(e) => setEmail(e.target.value)} />
                    </Form.Item>
                    <Form.Item
                        label="Password"
                        name="password"
                        rules={[{ required: true, message: 'Please input your password!' }]}
                    >
                        <Input.Password onChange={(e) => setPassword(e.target.value)} />
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" htmlType="submit">Login</Button>
                    </Form.Item>
                </Form>
                {loginError && <Alert message={loginError} type="error" showIcon />}
                <div style={{ marginTop: '10px' }}>
                    {/* Comment out or remove the Register button */}
                    {/* <Button type="link">
                        <Link to="/register">Register</Link>
                    </Button> */}
                    <Button type="link">
                        <Link to="/reset-password">Reset Account</Link>
                    </Button>
                </div>
            </div>
        </div>
    );
}

// Wrap the IndexPage component with ErrorBoundary
export default function IndexPageWithErrorBoundary() {
    return (
        <ErrorBoundary>
            <IndexPage />
        </ErrorBoundary>
    );
}
