import React from 'react';
import { Modal, Button } from 'antd';

const ProductDetailPopup = ({ product, visible, onClose }) => {
  // Add a null or undefined check for the 'product' object
  if (!product) {
    return null; // Return null if 'product' is null or undefined
  }

  // Calculate the profit as (EndUserPrice - YourPriceWithFees)
  const profit = product.EndUserPrice - product.YourPriceWithFees;

  return (
    <Modal
      title="Product Details"
      visible={visible}
      onCancel={onClose}
      footer={[
        <Button key="close" onClick={onClose}>
          Close
        </Button>
      ]}
    >
      {/* Load external image if 'product.ImageUrl' is provided */}
      {product.ImageUrl && (
        <img src={product.ImageUrl} alt={product.Name} style={{ maxWidth: '100%' }} />
      )}
      <p><strong>Name:</strong> {product.Name}</p>
      <p><strong>Producer:</strong> {product.ProducerName}</p>
      <p><strong>Part Number:</strong> {product.PartNumber}</p>
      <p><strong>Description:</strong> {product.Description}</p>
      <p><strong>Profit:</strong> {profit.toFixed(2)} ({((profit / product.YourPriceWithFees) * 100).toFixed(2)}%)</p>
      <p><strong>End User Price:</strong> {product.EndUserPrice}</p>
      <p><strong>Your Price With Fees:</strong> {product.YourPriceWithFees}</p>
      <p><strong>Warranty:</strong> {product.Warranty}</p>
      <p><strong>EANCode:</strong> {product.EANCode}</p>
      <p><strong>On Stock Text:</strong> {product.OnStockText}</p>
      <p><strong>Status:</strong> {product.Status}</p>
    </Modal>
  );
};

export default ProductDetailPopup;
