import React, { useState, useEffect } from 'react';
import { Table, Button, Modal, Form, Input, Switch } from 'antd';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import SharedLayout from './SharedLayout';
const apiUrl = process.env.REACT_APP_API_URL;

const UserPage = () => {
  const [users, setUsers] = useState([]);
  const [newUser, setNewUser] = useState({});
  const [isCreateModalVisible, setIsCreateModalVisible] = useState(false);
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem('authToken');
    if (token) {
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      fetchUsers();
    } else {
      navigate('/');
    }
  }, [navigate]);

  const fetchUsers = async () => {
    try {
      const response = await axios.get(`${apiUrl}/users/getusers`);
      setUsers(response.data);
    } catch (error) {
      console.error('Error fetching users:', error);
    }
  };

  const createUser = async () => {
    try {
      const response = await axios.post(`${apiUrl}/users`, {
        ...newUser,
        is_email_verified: newUser.is_email_verified ? 1 : 0,
      });
      setUsers([...users, response.data]);
      setNewUser({});
      setIsCreateModalVisible(false);
    } catch (error) {
      console.error('Error creating user:', error);
    }
  };

  const updateUser = async () => {
    try {
      await axios.put(`${apiUrl}/users/${selectedUser.id}`, selectedUser);
      const updatedUsers = users.map((user) =>
        user.id === selectedUser.id ? selectedUser : user
      );
      setUsers(updatedUsers);
      setIsEditModalVisible(false);
      setSelectedUser(null);
    } catch (error) {
      console.error('Error updating user:', error);
    }
  };

  const deleteUser = async (userId) => {
    try {
      await axios.delete(`${apiUrl}/users/${userId}`);
      const updatedUsers = users.filter((user) => user.id !== userId);
      setUsers(updatedUsers);
    } catch (error) {
      console.error('Error deleting user:', error);
    }
  };

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Username',
      dataIndex: 'username',
      key: 'username',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Is Email Verified',
      dataIndex: 'is_email_verified',
      key: 'is_email_verified',
      render: (isEmailVerified, record) => (
        <Switch
          checked={isEmailVerified === 1}
          onChange={() => handleIsEmailVerifiedChange(record)}
        />
      ),
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (text, record) => (
        <div>
          <Button type="primary" onClick={() => handleEditUser(record)}>
            Edit
          </Button>
          <Button type="danger" onClick={() => handleDeleteUser(record.id)}>
            Delete
          </Button>
        </div>
      ),
    },
  ];

  const handleEditUser = (user) => {
    setSelectedUser({ ...user });
    setIsEditModalVisible(true);
  };

  const handleDeleteUser = (userId) => {
    Modal.confirm({
      title: 'Delete User',
      content: 'Are you sure you want to delete this user?',
      onOk: () => deleteUser(userId),
    });
  };

  const handleIsEmailVerifiedChange = (record) => {
    const updatedUser = { ...record, is_email_verified: !record.is_email_verified };
    updateUser(updatedUser);
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  return (
    <SharedLayout>
      <div>
        <h1>User Page</h1>
        <Button type="primary" onClick={() => setIsCreateModalVisible(true)}>
          Create New User
        </Button>

        <Table dataSource={users} columns={columns} rowKey="id" />

        <Modal
          title="Create New User"
          visible={isCreateModalVisible}
          onOk={createUser}
          onCancel={() => {
            setIsCreateModalVisible(false);
            setNewUser({});
          }}
        >
          <Form>
            <Form.Item label="Username">
              <Input
                value={newUser.username || ''}
                onChange={(e) =>
                  setNewUser({ ...newUser, username: e.target.value })
                }
              />
            </Form.Item>
            <Form.Item label="Email">
              <Input
                value={newUser.email || ''}
                onChange={(e) =>
                  setNewUser({ ...newUser, email: e.target.value })
                }
              />
            </Form.Item>
            {/* ... Other user fields ... */}
            <Form.Item label="Password">
              <Input.Password
                value={newUser.password || ''}
                onChange={(e) =>
                  setNewUser({ ...newUser, password: e.target.value })
                }
              />
            </Form.Item>
            <Form.Item label="Is Email Verified">
              <Switch
                checked={newUser.is_email_verified === 1}
                onChange={(checked) =>
                  setNewUser({ ...newUser, is_email_verified: checked ? 1 : 0 })
                }
              />
            </Form.Item>
          </Form>
        </Modal>

        <Modal
          title="Edit User"
          visible={isEditModalVisible}
          onOk={updateUser}
          onCancel={() => {
            setIsEditModalVisible(false);
            setSelectedUser(null);
          }}
        >
          <Form>
            <Form.Item label="Username">
              <Input
                value={(selectedUser && selectedUser.username) || ''}
                onChange={(e) =>
                  setSelectedUser({
                    ...selectedUser,
                    username: e.target.value,
                  })
                }
              />
            </Form.Item>
            <Form.Item label="Email">
              <Input
                value={(selectedUser && selectedUser.email) || ''}
                onChange={(e) =>
                  setSelectedUser({
                    ...selectedUser,
                    email: e.target.value,
                  })
                }
              />
            </Form.Item>
            <Form.Item label="Is Email Verified">
              <Switch
                checked={(selectedUser && selectedUser.is_email_verified) === 1}
                onChange={(checked) =>
                  setSelectedUser({
                    ...selectedUser,
                    is_email_verified: checked ? 1 : 0,
                  })
                }
              />
            </Form.Item>
          </Form>
        </Modal>
      </div>
    </SharedLayout>
  );
};

export default UserPage;
