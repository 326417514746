// src/services/registrationService.js
// This service manages the user registration process,
// sending new user data to the server and handling the response.


const API_URL = process.env.REACT_APP_API_URL;

const registerUser = async (username, email, password) => {
    try {
        const response = await fetch(`${API_URL}/users/register`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ username, email, password }),
        });

        const responseText = await response.text(); // Get the response as text

        if (response.ok) {
            return true; // User registration was successful
        } else {
            console.error('Registration error:', responseText);
            throw new Error('Failed to register. Please try again.4');
        }
    } catch (error) {
        console.error('Registration error:', error);
        throw error;
    }
};

export { registerUser };