import React, { useState, useEffect } from 'react';
import { Layout, Menu, Typography } from 'antd'; // Import Typography from Ant Design
import { DatabaseOutlined, UserOutlined, LogoutOutlined, UsergroupAddOutlined } from '@ant-design/icons';
import { useNavigate, useLocation } from 'react-router-dom';

const { Header, Content, Footer, Sider } = Layout;
const { Text } = Typography; // Destructure the Text component from Typography

const SharedLayout = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [adminMenuOpen, setAdminMenuOpen] = useState(
    localStorage.getItem('adminMenuOpen') === 'true'
  );
  const [dataMenuOpen, setDataMenuOpen] = useState(
    localStorage.getItem('dataMenuOpen') === 'true'
  );
  const [username, setUsername] = useState(null); // State to store the username

  useEffect(() => {
    localStorage.setItem('adminMenuOpen', adminMenuOpen);
    localStorage.setItem('dataMenuOpen', dataMenuOpen);

    // Fetch the username from wherever it's stored and set it in the state
    const storedUsername = localStorage.getItem('username');
    setUsername(storedUsername);
  }, [adminMenuOpen, dataMenuOpen]);

  const handleMenuClick = ({ key }) => {
    navigate(key);
  };

  const handleAdminMenuOpenChange = (openKeys) => {
    setAdminMenuOpen(openKeys.includes('admin'));
  };

  const handleDataMenuOpenChange = (openKeys) => {
    setDataMenuOpen(openKeys.includes('data'));
  };

  const handleLogout = () => {
    localStorage.removeItem('authToken');
    navigate('/');
  };

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Sider collapsible>
        <Menu
          theme="dark"
          selectedKeys={[location.pathname]}
          openKeys={adminMenuOpen ? ['admin'] : []}
          mode="inline"
          onClick={handleMenuClick}
          onOpenChange={handleAdminMenuOpenChange}
        >
          <Menu.Item key="/dashboard" icon={<UserOutlined />} onClick={() => setAdminMenuOpen(false)}>
            Dashboard
          </Menu.Item>
          <Menu.SubMenu key="admin" icon={<UsergroupAddOutlined />} title="Administration">
            <Menu.Item key="/dashboard/users" onClick={() => setAdminMenuOpen(true)}>
              Users
            </Menu.Item>
            <Menu.Item key="/dashboard/suppliers" onClick={() => setAdminMenuOpen(true)}>
              Suppliers
            </Menu.Item>
          </Menu.SubMenu>
        </Menu>
        <Menu
          theme="dark"
          selectedKeys={[location.pathname]}
          openKeys={dataMenuOpen ? ['data'] : []}
          mode="inline"
          onClick={handleMenuClick}
          onOpenChange={handleDataMenuOpenChange}
        >
          <Menu.SubMenu key="data" icon={<DatabaseOutlined />} title="Data">
            <Menu.Item key="/dashboard/data/atcomp" onClick={() => setDataMenuOpen(true)}>
              ATcomp
            </Menu.Item>
            <Menu.Item key="/dashboard/data/botland" onClick={() => setDataMenuOpen(true)}>
              botland
            </Menu.Item>
            <Menu.Item key="/dashboard/data/edsystem" onClick={() => setDataMenuOpen(true)}>
              edSystem
            </Menu.Item>
            <Menu.Item key="/dashboard/data/westech" onClick={() => setDataMenuOpen(true)}>
              Westech
            </Menu.Item>
          </Menu.SubMenu>
<Menu.Item key="/dashboard/logout" icon={<LogoutOutlined />} onClick={handleLogout}>
            Logout
          </Menu.Item>
        </Menu>
      </Sider>
      <Layout className="site-layout">
        <Header style={{ padding: 0, background: '#fff', display: 'flex', justifyContent: 'flex-end' }}>
          {username && (
            <Text style={{ margin: '16px' }}>Welcome, {username}</Text>
          )}
        </Header>
        <Content style={{ margin: '0 16px' }}>
          <div style={{ padding: 24, minHeight: 360 }}>
            {children}
          </div>
        </Content>
        <Footer style={{ textAlign: 'center' }}>Your App ©2024 Created by Your Name</Footer>
      </Layout>
    </Layout>
  );
};

export default SharedLayout;