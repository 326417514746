// src/services/resetPasswordService.js
// This service handles the password reset functionality by sending 
// a request to the backend to initiate the password reset process.

const API_URL = process.env.REACT_APP_API_URL;

const resetPassword = async (email) => {
    try {
        const response = await fetch(`${API_URL}/users/request-reset`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ email }),
        });

        if (response.ok) {
            console.log('Reset password request successful');
            return true;
        } else {
            console.log('Reset password request failed');
            return false;
        }
    } catch (error) {
        console.error('Reset password error:', error);
        return false;
    }
};

export { resetPassword };