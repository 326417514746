import React, { useState, useEffect } from 'react';
import { Table, Button, Modal, Form, Input, Switch } from 'antd';
import axios from 'axios';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import SharedLayout from './SharedLayout'; // Import your SharedLayout component
const apiUrl = process.env.REACT_APP_API_URL;

const SupplierPage = () => {
  const [suppliers, setSuppliers] = useState([]);
  const [newSupplier, setNewSupplier] = useState({});
  const [isCreateModalVisible, setIsCreateModalVisible] = useState(false);
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [selectedSupplier, setSelectedSupplier] = useState(null);
  const [APIParameter, setAPIParameter] = useState(null);
  const [notes, setNotes] = useState(null);
  const navigate = useNavigate(); // Initialize the navigate function
  const loggedInUserID = localStorage.getItem('username'); // Retrieve the logged-in user's ID from local storage


    useEffect(() => {
    // Check if the user is authenticated (token exists)
    const token = localStorage.getItem('authToken');
    if (token) {
      // Set the Authorization header for all Axios requests
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      // Fetch suppliers only if authenticated
      fetchSuppliers();
    } else {
      // Redirect to the index page if not authenticated
      navigate('/');
    }
  }, [navigate]);

  const fetchSuppliers = async () => {
    try {
      const response = await axios.get(`${apiUrl}/suppliers/getsuppliers`);
      setSuppliers(response.data);
    } catch (error) {
      console.error('Error fetching suppliers:', error);
    }
  };

const createSupplier = async () => {
  try {
    const response = await axios.post(`${apiUrl}/suppliers/`, {
      ...newSupplier,
      IsActive: newSupplier.IsActive ? 1 : 0,
      APIParameter: APIParameter, // Include parameter
      Notes: notes, // Include notes
      CreatedByUserID: loggedInUserID, // Set the CreatedByUserID here
    });
    setSuppliers([...suppliers, response.data]);
    setNewSupplier({});
    setIsCreateModalVisible(false);
    setAPIParameter(''); // Reset APIParameter after successful creation
    setNotes(''); // Reset notes after successful creation
  } catch (error) {
    console.error('Error creating supplier:', error);
  }
};

const updateSupplier = async () => {
  try {
    const updatedSupplierData = {
      ...selectedSupplier,
      Parameter: APIParameter, // Include parameter
      Notes: notes, // Include notes
    };
    await axios.put(`${apiUrl}/suppliers/${selectedSupplier.SupplierID}`, updatedSupplierData);
    const updatedSuppliers = suppliers.map((supplier) =>
      supplier.SupplierID === selectedSupplier.SupplierID ? updatedSupplierData : supplier
    );
    setSuppliers(updatedSuppliers);
    setIsEditModalVisible(false);
    setSelectedSupplier(null);
    setAPIParameter(''); // Reset APIParameter after successful creation
    setNotes(''); // Reset notes after successful creation
  } catch (error) {
    console.error('Error updating supplier:', error);
  }
};

  const deleteSupplier = async (supplierId) => {
    try {
      await axios.delete(`${apiUrl}/suppliers/${supplierId}`);
      const updatedSuppliers = suppliers.filter((supplier) => supplier.SupplierID !== supplierId);
      setSuppliers(updatedSuppliers);
    } catch (error) {
      console.error('Error deleting supplier:', error);
    }
  };

  const columns = [
    {
      title: 'SupplierID',
      dataIndex: 'SupplierID',
      key: 'SupplierID',
    },
    {
      title: 'Name',
      dataIndex: 'Name',
      key: 'Name',
    },
    {
      title: 'API Endpoint',
      dataIndex: 'APIEndpoint',
      key: 'APIEndpoint',
    },
    {
      title: 'API Key',
      dataIndex: 'APIKey',
      key: 'APIKey',
    },
 {
    title: 'API Parameter', // Make sure the title is correct
    dataIndex: 'APIParameter', // Make sure the dataIndex matches your data structure
    key: 'APIParameter',
  },
    {
      title: 'API Username',
      dataIndex: 'APIUsername',
      key: 'APIUsername',
    },
{
    title: 'API Password',
    dataIndex: 'APIPassword',
    key: 'APIPassword',
    render: (APIPassword) => '*****', // Render asterisks for the password
  },
         {
      title: 'Notes',
      dataIndex: 'Notes',
      key: 'Notes',
    },

    {
      title: 'IsActive',
      dataIndex: 'IsActive',
      key: 'IsActive',
      render: (isActive, record) => (
        <Switch
          checked={isActive}
          onChange={() => handleIsActiveChange(record)}
        />
      ),
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (text, record) => (
        <div>
          <Button type="primary" onClick={() => handleEditSupplier(record)}>
            Edit
          </Button>
          <Button type="danger" onClick={() => handleDeleteSupplier(record.SupplierID)}>
            Delete
          </Button>
        </div>
      ),
    },
  ];

  const handleEditSupplier = (supplier) => {
    setSelectedSupplier({ ...supplier });
    setIsEditModalVisible(true);
  };

  const handleDeleteSupplier = (supplierId) => {
    Modal.confirm({
      title: 'Delete Supplier',
      content: 'Are you sure you want to delete this supplier?',
      onOk: () => deleteSupplier(supplierId),
    });
  };

  const handleIsActiveChange = (record) => {
    const updatedSupplier = { ...record, IsActive: !record.IsActive };
    updateSupplier(updatedSupplier);
  };

  useEffect(() => {
    fetchSuppliers();
  }, []);

  return (
    <SharedLayout> {/* Use the SharedLayout component here */}
      <div>
        <h1>Supplier Page</h1>
        <Button type="primary" onClick={() => setIsCreateModalVisible(true)}>
          Create New Supplier
        </Button>

        <Table dataSource={suppliers} columns={columns} rowKey="SupplierID" />

        <Modal
          title="Create New Supplier"
          visible={isCreateModalVisible}
          onOk={createSupplier}
          onCancel={() => {
            setIsCreateModalVisible(false);
            setNewSupplier({});
          }}
        >
          <Form>
            <Form.Item label="Name">
              <Input
                value={newSupplier.Name || ''}
                onChange={(e) =>
                  setNewSupplier({ ...newSupplier, Name: e.target.value })
                }
              />
            </Form.Item>
            <Form.Item label="API Endpoint">
              <Input
                value={newSupplier.APIEndpoint || ''}
                onChange={(e) =>
                  setNewSupplier({ ...newSupplier, APIEndpoint: e.target.value })
                }
              />
            </Form.Item>
            <Form.Item label="API Key">
              <Input
                value={newSupplier.APIKey || ''}
                onChange={(e) =>
                  setNewSupplier({ ...newSupplier, APIKey: e.target.value })
                }
              />
            </Form.Item>
            <Form.Item label="API Username">
              <Input
                value={newSupplier.APIUsername || ''}
                onChange={(e) =>
                  setNewSupplier({ ...newSupplier, APIUsername: e.target.value })
                }
              />
            </Form.Item>
            <Form.Item label="API Password">
              <Input.Password
                value={newSupplier.APIPassword || ''}
                onChange={(e) =>
                  setNewSupplier({ ...newSupplier, APIPassword: e.target.value })
                }
              />
            </Form.Item>
            <Form.Item label="APIParameter">
  <Input
    value={APIParameter}
    onChange={(e) => setAPIParameter(e.target.value)}
  />
</Form.Item>
<Form.Item label="Notes">
  <Input
    value={notes} // Make sure this value is coming from the state
    onChange={(e) => setNotes(e.target.value)}
  />
</Form.Item>
            <Form.Item label="IsActive">
              <Switch
                checked={newSupplier.IsActive === 1}
                onChange={(checked) =>
                  setNewSupplier({ ...newSupplier, IsActive: checked ? 1 : 0 })
                }
              />
            </Form.Item>
          </Form>
        </Modal>

        <Modal
          title="Edit Supplier"
          visible={isEditModalVisible}
          onOk={updateSupplier}
          onCancel={() => {
            setIsEditModalVisible(false);
            setSelectedSupplier(null);
          }}
        >
          <Form>
            <Form.Item label="Name">
              <Input
                value={(selectedSupplier && selectedSupplier.Name) || ''}
                onChange={(e) =>
                  setSelectedSupplier({
                    ...selectedSupplier,
                    Name: e.target.value,
                  })
                }
              />
            </Form.Item>
            <Form.Item label="API Endpoint">
              <Input
                value={(selectedSupplier && selectedSupplier.APIEndpoint) || ''}
                onChange={(e) =>
                  setSelectedSupplier({
                    ...selectedSupplier,
                    APIEndpoint: e.target.value,
                  })
                }
              />
            </Form.Item>
            <Form.Item label="API Key">
              <Input
                value={(selectedSupplier && selectedSupplier.APIKey) || ''}
                onChange={(e) =>
                  setSelectedSupplier({
                    ...selectedSupplier,
                    APIKey: e.target.value,
                  })
                }
              />
            </Form.Item>
<Form.Item label="APIParameter">
  <Input
    value={APIParameter}
    onChange={(e) => setAPIParameter(e.target.value)}
  />
</Form.Item>


            <Form.Item label="API Username">
              <Input
                value={(selectedSupplier && selectedSupplier.APIUsername) || ''}
                onChange={(e) =>
                  setSelectedSupplier({
                    ...selectedSupplier,
                    APIUsername: e.target.value,
                  })
                }
              />
            </Form.Item>
            <Form.Item label="API Password">
              <Input.Password
                value={(selectedSupplier && selectedSupplier.APIPassword) || ''}
                onChange={(e) =>
                  setSelectedSupplier({
                    ...selectedSupplier,
                    APIPassword: e.target.value,
                  })
                }
              />
            </Form.Item>
            <Form.Item label="IsActive">
              <Switch
                checked={(selectedSupplier && selectedSupplier.IsActive) || false}
                onChange={(checked) =>
                  setSelectedSupplier({
                    ...selectedSupplier,
                    IsActive: checked,
                  })
                }
              />
            </Form.Item>
            <Form.Item label="Notes">
            <Input
               value={notes}
               onChange={(e) => setNotes(e.target.value)}
              />
            </Form.Item>
          </Form>
        </Modal>
      </div>
    </SharedLayout>
  );
};

export default SupplierPage;