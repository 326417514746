import React, { useState, useEffect } from 'react';
import { Table, Button, Modal, Input, Select } from 'antd';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import SharedLayout from './SharedLayout';
import ProductDetailPopup from './ProductDetailPopup';

const { Option } = Select;

const apiUrl = process.env.REACT_APP_API_URL;


const EDSystemProducts = () => {
  const [products, setProducts] = useState([]);
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [APIParameter, setAPIParameter] = useState(null);
  const [notes, setNotes] = useState(null);
  const [filterInStock, setFilterInStock] = useState(null);
  const [filterEANCode, setFilterEANCode] = useState('');
  const [filterPartNumber, setFilterPartNumber] = useState('');
  const [filterProducerName, setFilterProducerName] = useState('');
  const navigate = useNavigate();
  const [isDetailPopupVisible, setIsDetailPopupVisible] = useState(false);


 const handleOpenDetailPopup = (product) => {
    setSelectedProduct(product);
    setIsDetailPopupVisible(true);
  };

  const handleCloseDetailPopup = () => {
    setSelectedProduct(null);
    setIsDetailPopupVisible(false);
  };

  useEffect(() => {
    const token = localStorage.getItem('authToken');
    if (token) {
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      fetchProducts();
    } else {
      navigate('/');
    }
  }, [navigate]);

  const fetchProducts = async () => {
    try {
      const response = await axios.get(`${apiUrl}/data/edsystem/products`);
      setProducts(response.data);
    } catch (error) {
      console.error('Error fetching products:', error);
    }
  };

  const updateProduct = async () => {
    try {
      const updatedProductData = {
        ...selectedProduct,
        Parameter: APIParameter,
        Notes: notes,
      };
      await axios.put(
        `${apiUrl}/data/edsystem/products/${selectedProduct.ProId}`,
        updatedProductData
      );
      const updatedProducts = products.map((product) =>
        product.ProId === selectedProduct.ProId ? updatedProductData : product
      );
      setProducts(updatedProducts);
      setIsEditModalVisible(false);
      setSelectedProduct(null);
      setAPIParameter('');
      setNotes('');
    } catch (error) {
      console.error('Error updating product:', error);
    }
  };

  const deleteProduct = async (productId) => {
    try {
      await axios.delete(`${apiUrl}/data/edsystem/products/${productId}`);
      const updatedProducts = products.filter((product) => product.ProId !== productId);
      setProducts(updatedProducts);
    } catch (error) {
      console.error('Error deleting product:', error);
    }
  };

const [sortField, setSortField] = useState(null);
  const [sortOrder, setSortOrder] = useState('asc');
  const [profitSortOrder, setProfitSortOrder] = useState(null);
  const [dealerSortOrder, setDealerSortOrder] = useState(null);
  const [qtyStockSortOrder, setQtyStockSortOrder] = useState(null); // Add qtyStockSortOrder


 const handleSortChange = (field) => {
    if (sortField === field) {
      if (field === 'Profit') {
        setProfitSortOrder(profitSortOrder === 'asc' ? 'desc' : 'asc');
        setDealerSortOrder(null);
        setQtyStockSortOrder(null); // Reset qtyStockSortOrder
      } else if (field === 'DealerPrice') {
        setDealerSortOrder(dealerSortOrder === 'asc' ? 'desc' : 'asc');
        setProfitSortOrder(null);
        setQtyStockSortOrder(null); // Reset qtyStockSortOrder
      } else if (field === 'OnStockText') {
        setQtyStockSortOrder(qtyStockSortOrder === 'asc' ? 'desc' : 'asc'); // Handle qtyStockSortOrder
        setProfitSortOrder(null);
        setDealerSortOrder(null);
      } else {
        setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
        setProfitSortOrder(null);
        setDealerSortOrder(null);
        setQtyStockSortOrder(null);
      }
    } else {
      setSortField(field);
      setSortOrder('asc');
      setProfitSortOrder(null);
      setDealerSortOrder(null);
      setQtyStockSortOrder(null);
    }
  };

  const sortedProducts = products.slice().sort((a, b) => {
    if (sortField === 'ProducerName') {
      return sortOrder === 'asc'
        ? a.ProducerName.localeCompare(b.ProducerName)
        : b.ProducerName.localeCompare(a.ProducerName);
    } else if (sortField === 'Profit') {
      return profitSortOrder === 'asc' ? a.Profit - b.Profit : b.Profit - a.Profit;
    } else if (sortField === 'YourPriceWithFees') {
      return dealerSortOrder === 'asc' ? a.YourPriceWithFees - b.YourPriceWithFees : b.YourPriceWithFees - a.YourPriceWithFees;
    } else if (sortField === 'OnStockText') {
      return qtyStockSortOrder === 'asc' // Handle qtyStockSortOrder
        ? a.OnStockText.localeCompare(b.OnStockText)
        : b.OnStockText.localeCompare(a.OnStockText);
    }
    return 0;
  });

const mapOnStockTextToNumber = (onStockText) => {
  const mapping = {
    '0,000': 0,
    '1,000': 1,
    '4,000': 4,
    '5,000': 5,
    '2,000': 2,
    '3,000': 3,
    '7,000': 7,
    '6,000': 6,
    '8,000': 8,
    '9,000': 9,
  };

  // Handle special cases like '10-49', '50-99', and '100+'
  if (onStockText === '10-49') {
    return 10;
  } else if (onStockText === '50-99') {
    return 50;
  } else if (onStockText === '100+') {
    return 100;
  }

  return mapping[onStockText] || parseInt(onStockText.replace(',', ''), 10);
};

  const columns = [
    {
      title: 'ProId',
      dataIndex: 'ProId',
      key: 'ProId',
      width: 'auto',
      render: (text, record) => (
        <Button type="link" onClick={() => handleOpenDetailPopup(record)}>
          {text}
        </Button>
      ),
    },
    {
      title: 'Profit',
      dataIndex: 'Profit',
      key: 'Profit',
      width: 'auto',
      sorter: true,
      sortOrder: sortField === 'Profit' ? profitSortOrder : null,
      onHeaderCell: () => ({
        onClick: () => handleSortChange('Profit'),
        style: { cursor: 'pointer' },
      }),
      render: (text, record) => {
        const profit = record.EndUserPrice - record.YourPriceWithFees;
        return (
          <span>
            {profit.toFixed(2)}
            {' ('}
            {((profit / record.YourPriceWithFees) * 100).toFixed(2)}%
            {')'}
          </span>
        );
      },
    },
    {
      title: 'Producer',
      dataIndex: 'ProducerName',
      key: 'ProducerName',
      width: 'auto',
      sorter: true,
      sortOrder: sortField === 'ProducerName' ? sortOrder : null,
      onHeaderCell: () => ({
        onClick: () => handleSortChange('ProducerName'),
        style: { cursor: 'pointer' },
      }),
    },
    {
      title: 'Name',
      dataIndex: 'Name',
      key: 'Name',
      width: 'auto',
    },
    {
      title: 'PartNumber',
      dataIndex: 'PartNumber',
      key: 'PartNumber',
      width: 'auto',
    },
    {
      title: 'EANC',
      dataIndex: 'EANCode',
      key: 'EANCode',
      width: 'auto',
    },
    {
      title: 'Dealer',
      dataIndex: 'DealerPrice',
      key: 'DealerPrice',
      width: 'auto',
      sorter: true,
      sortOrder: sortField === 'DealerPrice' ? dealerSortOrder : null,
      onHeaderCell: () => ({
        onClick: () => handleSortChange('DealerPrice'),
        style: { cursor: 'pointer' },
      }),
    },
    {
      title: 'EndPrice',
      dataIndex: 'EndUserPrice',
      key: 'EndUserPrice',
      width: 'auto',
    },
    {
      title: 'Warranty',
      dataIndex: 'Warranty',
      key: 'Warranty',
      width: 'auto',
    },
    {
      title: 'Qty Stock',
      dataIndex: 'OnStockText',
      key: 'OnStockText',
      width: 'auto',
      sorter: true,
      render: (onStockText) => mapOnStockTextToNumber(onStockText),
      sortOrder: sortField === 'OnStockText' ? qtyStockSortOrder : null,
      onHeaderCell: () => ({
        onClick: () => handleSortChange('OnStockText'),
        style: { cursor: 'pointer' },
      }),
    },
    {
      title: 'OnStock',
      dataIndex: 'OnStock',
      key: 'OnStock',
      width: 'auto',
      render: (onStock) => (onStock ? 'Yes' : 'No'),
    },
    {
      title: 'Image',
      dataIndex: 'ImageUrl',
      key: 'ImageUrl',
      width: 'auto',
      render: (imageUrl, record) => {
        if (imageUrl) {
          return (
            <img
              src={imageUrl}
              alt={record.Name}
              style={{ width: '50px', height: 'auto' }}
              onError={() => console.error(`Error loading image for product: ${record.ProId}`)}
            />
          );
        } else {
          return 'No Image';
        }
      },
    },
    {
      title: 'Actions',
      key: 'actions',
      width: 'auto',
      render: (text, record) => (
        <div>
          <Button type="primary" onClick={() => handleEditProduct(record)}>
            Edit
          </Button>
          <Button type="danger" onClick={() => handleDeleteProduct(record.ProId)}>
            Delete
          </Button>
        </div>
      ),
    },
  ];

  const handleEditProduct = (product) => {
    setSelectedProduct({ ...product });
    setIsEditModalVisible(true);
  };

  const handleDeleteProduct = (productId) => {
    Modal.confirm({
      title: 'Delete Product',
      content: 'Are you sure you want to delete this product?',
      onOk: () => deleteProduct(productId),
    });
  };

  useEffect(() => {
    fetchProducts();
  }, []);

  return (
    <SharedLayout>
      <div>
        <h1>Product Page</h1>
        <div style={{ marginBottom: 16 }}>
          <Input
            placeholder="Filter by EANCode"
            value={filterEANCode}
            onChange={(e) => setFilterEANCode(e.target.value)}
          />
          <Input
            placeholder="Filter by PartNumber"
            value={filterPartNumber}
            onChange={(e) => setFilterPartNumber(e.target.value)}
          />
          <Input
            placeholder="Filter by ProducerName"
            value={filterProducerName}
            onChange={(e) => setFilterProducerName(e.target.value)}
          />
          <Select
            placeholder="Filter by inStock"
            allowClear
            style={{ width: 120, marginRight: 8 }}
            onChange={(value) => setFilterInStock(value)}
          >
            <Option value={true}>Yes</Option>
            <Option value={false}>No</Option>
          </Select>
        </div>
        <Table
          dataSource={sortedProducts.filter((product) =>
            (!filterEANCode || product.EANCode.includes(filterEANCode)) &&
            (!filterPartNumber || product.PartNumber.includes(filterPartNumber)) &&
            (!filterProducerName || product.ProducerName.includes(filterProducerName)) &&
            (filterInStock === null || product.OnStock === filterInStock)
          )}
          columns={columns}
          rowKey="ProId"
        />

        <Modal
          title="Edit Product"
          visible={isEditModalVisible}
          onOk={updateProduct}
          onCancel={() => {
            setIsEditModalVisible(false);
            setSelectedProduct(null);
          }}
        >
          {/* Form for editing the selected product */}
          <Input
            placeholder="API Parameter"
            value={APIParameter || ''}
            onChange={(e) => setAPIParameter(e.target.value)}
          />
          <Input
            placeholder="Notes"
            value={notes || ''}
            onChange={(e) => setNotes(e.target.value)}
          />
        </Modal>
      </div>
      {/* Render the ProductDetailPopup component */}
      <ProductDetailPopup
        product={selectedProduct}
        visible={isDetailPopupVisible}
        onClose={handleCloseDetailPopup}
      />
    </SharedLayout>
  );
};

export default EDSystemProducts;
