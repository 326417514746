import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import IndexPage from './components/IndexPage';
import Login from './components/Login';
import Register from './components/Register';
import ResetPassword from './components/ResetPassword';
import Dashboard from './components/Dashboard';
import Users from './components/UserManagement';
import ResetPasswordForm from './components/ResetPasswordForm';
import EDSystemProducts from './components/edsystemproducts'; // Import the component
import Suppliers from './components/SupplierPage';



function App() {
    // Uncomment and use these states as needed:
    // const [isAuthenticated, setIsAuthenticated] = useState(false);
    // const [ws, setWs] = useState(null);

    useEffect(() => {
        // Example: Authentication check (replace with actual logic as needed)
        const checkAuthentication = async () => {
            try {
                const response = await fetch('/check-auth'); // Update with actual API endpoint
                if (response.ok) {
                    // setIsAuthenticated(true); // Uncomment if using isAuthenticated state
                } else {
                    // setIsAuthenticated(false); // Uncomment if using isAuthenticated state
                }
            } catch (error) {
                console.error('Authentication check failed:', error);
                // setIsAuthenticated(false); // Uncomment if using isAuthenticated state
            }
        };

        checkAuthentication();

        // WebSocket connection setup
        const socket = new WebSocket('wss://www.datament.sk/ws');

        socket.onopen = () => {
            console.log("Connected to WebSocket server");
            // You can send a message here if needed, e.g., socket.send(JSON.stringify({ type: 'hello' }));
        };

        socket.onmessage = (event) => {
            console.log("Received data from WebSocket server:", event.data);
            // Handle WebSocket messages here
        };

        socket.onerror = (error) => {
            console.error("WebSocket error:", error);
        };

        socket.onclose = () => {
            console.log("WebSocket connection closed");
        };

        // setWs(socket); // Uncomment if using ws state

        // Cleanup function to close WebSocket connection
        return () => {
            socket.close();
        };
    }, []); // The dependencies array is empty, meaning this effect runs once on mount


    return (
        <Router>
            <Routes>
                <Route exact path="/" element={<IndexPage />} />
                <Route path="/login" element={<Login />} />
                <Route path="/dashboard/*" element={<Dashboard />} />
                <Route path="/dashboard/suppliers" element={<Suppliers />} />
                <Route path="/dashboard/users" element={<Users />} />
                <Route path="/dashboard/data/edsystem" element={<EDSystemProducts />} /> 
                <Route path="/register" element={<Register />} />
                <Route path="/reset-password" element={<ResetPassword />} />
                <Route path="/reset-password/:token" element={<ResetPasswordForm />} />
            </Routes>
        </Router>
    );
}

export default App;